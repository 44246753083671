import React from "react";
import classes from "./_button.module.scss";

export const Button = ({ text, type, hero, prereg, btcolor, onClick }) => {
  return (
    <button
      className={classes.button}
      type={type}
      hero={hero}
      prereg={prereg}
      btcolor={btcolor}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
