import React from "react";
import classes from "./_title.module.scss";

export const Title = ({ text, ready }) => {
  return (
    <h1 className={classes.title} ready={ready}>
      {text}
    </h1>
  );
};

export const Subtitle = ({ text }) => {
  return <h2 className={classes.subtitle}>{text}</h2>;
};
