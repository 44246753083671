import React from "react";
import shadow from "../../assets/img-sistema/svg/shadow.svg";
import classes from "./_apps.module.scss";

export const Apps = ({ text, icon, ready }) => {
  return (
    <div className={classes.apps}>
      <div className={classes.apps__img}>
        <img className={classes.apps__img__icon} alt={text} src={icon} />
        <img className={classes.apps__img__shadow} alt="shadow" src={shadow} />
      </div>
      <label ready={ready}>{text}</label>
    </div>
  );
};
