import React, { useState, useEffect } from "react";
import classes from "./_navbar.module.scss";

export const NavBar = () => {
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`${classes.navbar} ${scroll ? classes.navbarScroll : ""}`}>
      <div className={classes.navbar__login}>
        <a href="#Login">Login</a>
      </div>

      <a href="#Apps">Apps</a>
      <a href="#ComoFunciona">Como Funciona?</a>
      <a href="#Assinar">Assinar</a>
      <a href="#Screens">Screens</a>
      <a href="#Contato">Contato </a>
    </nav>
  );
};
