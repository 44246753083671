import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "./globalStyle/GlobalStyle";
import { ToastContainer } from "react-toastify";
import { Home } from "./pages/Home";
import { PreLaunch } from "./pages/PreLaunch";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <GlobalStyle>
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path="/prelaunch" element={<Home />}></Route>
          <Route exact path="/" element={<PreLaunch />}></Route>
        </Routes>
      </Router>
    </GlobalStyle>
  );
}

export default App;
