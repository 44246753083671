import React from "react";
import { Button } from "../Button/Button";
import classes from "./_signCard.module.scss";

export const SignCardFree = ({ title, btcolor, bgcolor }) => {
  return (
    <div className={classes.signCard}>
      <div className={classes.signCard__titleBox} bgcolor={bgcolor}>
        <h5>uway</h5>
        <h1 className={classes.signCard__titleBox__title}>{title}</h1>
      </div>
      <div className={classes.signCard__contentBox}>
        <h1>
          {" "}
          7 dias <br />
          gratuitos
        </h1>
        <p>
          Para testar <br /> o app de sua escolha*
        </p>
        <span>
          *Ferramentas como salvar, exportar e cadastrar pacientes não
          disponíveis
        </span>
        <Button text="Testar" type="button" hero="false" btcolor={btcolor} />
      </div>
    </div>
  );
};

export const SignCard = ({
  title,
  btcolor,
  bgcolor,
  nApps,
  preco1,
  preco2,
  preco3,
}) => {
  return (
    <div className={classes.signCard}>
      <div className={classes.signCard__titleBox} bgcolor={bgcolor}>
        <h5>uway</h5>
        <h1 className={classes.signCard__titleBox__title}>{title}</h1>
      </div>
      <div className={classes.signCard__contentPriceBox}>
        <h1 bgcolor={bgcolor}> {nApps} apps </h1>
        <p>A sua escolha</p>
        <div className={classes.signCard__contentPriceBox__priceRow}>
          <div
            className={classes.signCard__contentPriceBox__priceRow__userLabel}
          >
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text1
              }
            >
              1
            </span>
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text2
              }
            >
              Usuário
            </span>
          </div>
          <div
            className={classes.signCard__contentPriceBox__priceRow__userLabel}
          >
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text2
              }
            >
              R$
            </span>
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text1
              }
            >
              {preco1}
            </span>
          </div>
          <Button text="Assinar" type="button" hero="false" btcolor={btcolor} />
        </div>

        <div className={classes.signCard__contentPriceBox__priceRow}>
          <div
            className={classes.signCard__contentPriceBox__priceRow__userLabel}
          >
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text1
              }
            >
              3
            </span>
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text2
              }
            >
              Usuário
            </span>
          </div>
          <div
            className={classes.signCard__contentPriceBox__priceRow__userLabel}
          >
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text2
              }
            >
              R$
            </span>
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text1
              }
            >
              {preco2}
            </span>
          </div>
          <Button text="Assinar" type="button" hero="false" btcolor={btcolor} />
        </div>

        <div
          className={classes.signCard__contentPriceBox__priceRow}
          style={{ borderBottom: 0 }}
        >
          <div
            className={classes.signCard__contentPriceBox__priceRow__userLabel}
          >
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text1
              }
            >
              10
            </span>
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text2
              }
            >
              Usuários
            </span>
          </div>
          <div
            className={classes.signCard__contentPriceBox__priceRow__userLabel}
          >
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text2
              }
            >
              R$
            </span>
            <span
              className={
                classes.signCard__contentPriceBox__priceRow__userLabel__text1
              }
            >
              {preco3}
            </span>
          </div>
          <Button text="Assinar" type="button" hero="false" btcolor={btcolor} />
        </div>
      </div>
    </div>
  );
};
